import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CSS from 'csstype';
import { ownerOf } from '../nft';
import Automata from './Automata';
import Button from './Button';
import { getHexStringFromBitArray } from '../utils';

function getRandomRule() {
    const rule: number[] = [];
    for (let i = 0; i < 32; i++) {
        rule.push(Math.random() > .5 ? 1 : 0);
    }
    return rule;
}

function getRandomInitialRow() {
    const initialRow: number[] = [];
    for (let i = 0; i < 224; i++) {
        initialRow.push(Math.random() > .5 ? 1 : 0);
    }
    return initialRow;
}

function getBitArrayFromHexString(hexString: string) {
    const bitArray: number[] = [];
    for (let i = 0; i < hexString.length; i++) {
        const value = parseInt(hexString.charAt(i), 16);
        for (let j = 0; j < 4; j++) {
            bitArray.push((value >> (3 - j)) & 1);
        }
    }
    return bitArray;
}

function getBitArrayFromTokenId(tokenId: string) {
    const padding: number[] = Array(256).fill(0);
    const value = getBitArrayFromHexString(tokenId);
    const valueWithPadding = [...padding, ...value];
    return valueWithPadding.slice(-256);
}

export default function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const [rule, setRule] = useState<number[]>(Array(32).fill(0));
    const [initialRow, setInitialRow] = useState<number[]>(Array(224).fill(0));
    const [owner, setOwner] = useState<string | undefined>(undefined);
    const [unowned, setUnowned] = useState<boolean | undefined>(undefined);
    const [checkingOwnership, setCheckingOwnership] = useState<boolean>(false);

    useEffect(() => {
        const pathNamePieces = location.pathname.split('/');
        if (pathNamePieces.length >= 3) {
            const tokenId = location.pathname.split('/')[2];
            const tokenIdBitArray = getBitArrayFromTokenId(tokenId);
            setRule(tokenIdBitArray.slice(0, 32));
            setInitialRow(tokenIdBitArray.slice(32, 256));
            setOwner(undefined);
            setUnowned(undefined);
        } else {
            const randomRule = getRandomRule();
            const randomInitialRow = getRandomInitialRow();
            navigate(`/token/${getHexStringFromBitArray([...randomRule, ...randomInitialRow])}`);
        }
    }, [navigate, location.pathname]);

    return (
        <div style={containerStyle}>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
                <Automata rule={rule} initialRow={initialRow} owner={owner} unowned={unowned} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginBottom: '.5em' }} hidden={!checkingOwnership}>
                    <div className="loader" />
                </div>
                <div style={{ margin: 4 }}>
                    <Button text="Generate New" onClick={() => {
                        navigate(`/token/${getHexStringFromBitArray([...getRandomRule(), ...getRandomInitialRow()])}`);
                    }} />
                </div>
                <div style={{ margin: 4 }}>
                    <Button text="Check Ownership" onClick={async () => {
                        setCheckingOwnership(true);
                        const owner = await ownerOf(`0x${getHexStringFromBitArray([...rule, ...initialRow])}`);
                        setOwner(owner);
                        setUnowned(owner === undefined);
                        setCheckingOwnership(false);
                    }} />
                </div>
                <div style={{ margin: 4 }}>
                    <Button text="Mint" onClick={() => {
                        navigate(`/mint/${getHexStringFromBitArray([...rule, ...initialRow])}`);
                    }} />
                </div>
            </div>
        </div>
    );
}

const containerStyle: CSS.Properties = {
    paddingLeft: '2em',
    paddingRight: '2em',
    marginTop: '2em',
    maxWidth: '800px'
};
