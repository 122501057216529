import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import { FeeMarketEIP1559Transaction } from '@ethereumjs/tx';

const ContractABI = [
    {
        constant: false,
        inputs: [
            {
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'mint',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256'
            }
        ],
        name: 'ownerOf',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: "getNumMinted",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256"
            }
        ],
        name: "tokenURI",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string"
            }
        ],
        stateMutability: "view",
        type: "function"
    }
];
const CONTRACT_ADDRESS = '0x6572af38ffa542dfece2a34e8b74b42fc93d53f6';

const web3 = createAlchemyWeb3(`https://eth-mainnet.alchemyapi.io/v2/L0uLPlaAgP3GWYLZVGSLv5Kxqc6YIDqa`);
const contract = new web3.eth.Contract(ContractABI as any, CONTRACT_ADDRESS);


export async function ownerOf(tokenId: string) {
    try {
        const encodedTokenId = web3.eth.abi.encodeParameter('uint256', tokenId);
        const result = await contract.methods.ownerOf(encodedTokenId).call();
        return result;
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

export async function getNumMinted() {
    try {
        const result = await contract.methods.getNumMinted().call();
        return result;
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

export async function mintWithMetaMask(tokenId: string, value: string) {
    try {
        const ethereum = (window as any).ethereum;

        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        if (!tokenId.startsWith('0x')) {
            tokenId = '0x' + tokenId;
        }

        const encodedTokenId = web3.eth.abi.encodeParameter('uint256', tokenId);

        const transactionParameters = {
            gas: web3.utils.numberToHex(100000),
            to: CONTRACT_ADDRESS,
            from: account,
            value: web3.utils.numberToHex(web3.utils.toWei(value, 'ether')),
            data: contract.methods.mint(encodedTokenId).encodeABI()
        };

        const txHash = await ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });

        return txHash;
    } catch (e) {
        alert('Error\n' + JSON.stringify(e, null, 2));
        return undefined;
    }
}

export async function generateMintTransaction(tokenId: string, address: string, mintingFee: string, gasFee: string, priorityFee: string) {
    if (!tokenId.startsWith('0x')) {
        tokenId = '0x' + tokenId;
    }

    const encodedTokenId = web3.eth.abi.encodeParameter('uint256', tokenId);
    const transactionCount = await web3.eth.getTransactionCount(address, 'pending');
    const txObject = {
        chainId: 1,
        to: CONTRACT_ADDRESS,
        from: address,
        nonce: web3.utils.numberToHex(transactionCount),
        value: web3.utils.numberToHex(web3.utils.toWei(mintingFee, 'ether')),
        data: contract.methods.mint(encodedTokenId).encodeABI(),
        gasLimit: web3.utils.numberToHex(100000),
        maxFeePerGas: web3.utils.numberToHex(web3.utils.toWei(gasFee, 'gwei')),
        maxPriorityFeePerGas: web3.utils.numberToHex(web3.utils.toWei(priorityFee, 'gwei')),
    };
    return FeeMarketEIP1559Transaction.fromTxData(txObject);
}

export async function signTransaction(tx: FeeMarketEIP1559Transaction, privateKey: string) {
    if (privateKey.startsWith('0x')) {
        privateKey = privateKey.slice(2);
    }

    const privateKeyBuffer = Buffer.from(privateKey, 'hex')
    const signedTx = tx.sign(privateKeyBuffer);
    const serializedSignedTx = signedTx.serialize();
    const rawSignedTxHex = '0x' + serializedSignedTx.toString('hex');
    return rawSignedTxHex;
}

export async function sendTransaction(tx: string): Promise<any> {
    return new Promise((resolve, reject) => {
        try {
            web3.eth.sendSignedTransaction(tx)
                .on('transactionHash', hash => resolve(hash))
                .catch(error => { reject(error); });
        } catch (e) {
            reject(e);
        }
    });
}
