import React from 'react';
import CSS from 'csstype';
import ParentStateExample from './ParentStateExample';
import RuleExample from './RuleExample';

export default function About() {
    const [bits, setBits] = React.useState<number[]>([0, 0, 0, 0, 1]);
    const [parentBits, setParentBits] = React.useState<number[]>([0, 0, 0, 0, 1]);

    return (
        <div style={containerStyle}>
            <h2>What do you own when you own an NFT?</h2>
            <p>Fundamentally, an NFT is a 256 bit number. When you own an NFT, you own a number.</p>
            <p>NFTs are also tied to a smart contract address, so a token with ID 1147	from Bored Ape Yacht Club (address <span style={{ wordBreak: 'break-all' }}>0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D</span>) is different from a token with ID 1147 from Automata NFT (address <span style={{ wordBreak: 'break-all' }}>0x6572af38ffa542dfece2a34e8b74b42fc93d53f6</span>). Each address represents a collection of NFTs. For a given address, each token (256 bit number) is unique and can only be owned by one account.</p>
            <h2>I thought NFTs were images. How is a number an image?</h2>
            <p>A number is not an image, but you can create a mapping from numbers to images. However, this mapping, nor the images, are (usually) stored on the blockchain, so you have to trust an off-chain service to maintain it and ensure that different numbers don't result in the same image for a token that's supposed to be unique.</p>
            <p>NFT images are often stored on the IPFS to reduce the dependency on a centralized service, and newer token standards support attaching additional data to the token so that the IPFS address, or any other information (e.g. the hash of the image), is part of the token. However, this still doesn't make the connection between a NFT and an image direct.</p>
            <p>There are generative NFTs that use the token number as an input to a program that generates an image, but it's often not transparent how the generation happens.</p>
            <h2>The purest NFT</h2>
            <p>Automata NFT captures the essence of what NFTs are by making a direct and simple translation from token number to image.</p>
            <p>We do this by having the token number encode a <a href="https://en.wikipedia.org/wiki/Cellular_automaton" target="_blank" rel="noopener noreferrer">cellular automata</a>. The first 32 bits specify how each row is produced from the previous row, and the remaining 224 bits define the first row.</p>
            <h2>Rules for producing rows</h2>
            <p>Each cell is set based on its parents - the 5 cells above it (directly above, then one to the left, one to the right, two to the left, and two to the right). Since cells have 5 parents, each with 2 possible states, there are 32 different states a cell's parents can be in.</p>
            <p>To convert a parent state to a number between 0 and 31, we treat it as a 5 bit number. Try it! (Click on the cells to toggle them on and off).</p>
            <ParentStateExample bits={bits} setBits={setBits} />
            <p>The rule for an automata specifies a child state for each of these 32 parent states, and hence can be encoded in 32 bits. The first bit is what the child will be if the parents are in state 0, the second bit what the child will be if the parents are in state 1, and so on. Try it! (In addition to toggling the state of the parent cells, you can click on the numbers in the rule to toggle them).</p>
            <RuleExample parentBits={parentBits} setParentBits={(parentBits: number[]) => {setParentBits(parentBits); setBits(parentBits);}} />
            <p>For example, note how the rule 01010101010101010101010101010101 (0x55555555) has the child on if the parents form an odd number and the child off if the parents form an even number. And note that the parents form an odd number if and only if the far right parent is on.</p>
            <h2>Specifying a complete automata</h2>
            <p>Using the rule, we can generate a new row from the previous row by setting each cell based on the rule and its parents. Then we can generate the next row, and the next row, and so on.</p>
            <p>By specifying the rule and the initial row, we specify the entire automata.</p>
            <p>We already saw how to encode the rule in 32 bits. We can encode the initial row of 224 cells (224 + 32 = 256) the same way we map parent cells to a number, the only difference being we have 224 cells instead of 5.</p>
            <p>Then by concatenating the encoding of the rule and the encoding of the initial row together, we have a 256 bit number. This is the token ID for the Automata NFT.</p>
            <p>For example, to generate a checkerboard, we can use the rule F0F0F0F0 (this rule turns the child on if its direct parent is off, and the child off if its direct parent is on - try it above!) and the initial row AAA...A (56 As) (every other cell on). Concatenating these gives a token ID of 0xF0F0F0F0AAA...A, which you can view <a href="/token/F0F0F0F0AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA" target="_blank" rel="noopener noreferrer">here</a> (you can view any automata by going to automatanft.com/token/[tokenId]).</p>
            <p>In this way, we create a simple, direct mapping from a token ID to an image. When you own an Automata NFT, it's much more like owning the corresponding image than with other NFTs.</p>
            <h2>Initial supply</h2>
            <p>When Automata NFT was created, 64 random tokens were minted.
            </p>
            <h2>Minting</h2>
            <p>New tokens can be minted for a fee. Each new token increases this fee.</p>
            <p>In general, the nth token minted costs (.0001)*(n^2)ETH. So the first token minted costs .0001ETH, the 10th .01ETH, the 100th 1ETH, the 1000th 100ETH.</p>
            <p>It's likely there will never be more than 1000 Automata NFTs.</p>
            <p>Unowned tokens can be minted from their token page - automatanft.com/token/[tokenId].</p>
        </div>
    );
}

const containerStyle: CSS.Properties = {
    paddingLeft: '2em',
    paddingRight: '2em',
    marginTop: '2em',
    maxWidth: '800px'
};