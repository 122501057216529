import React from 'react';
import { getHexStringFromBitArray } from '../utils';

interface P {
    parentBits: number[];
    setParentBits: (parentBits: number[]) => void;
}

export default function RuleExample(props: P) {
    const [rule, setRule] = React.useState<number[]>([0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1]);
    const parentsValue = props.parentBits[4] + 2 * props.parentBits[3] + 4 * props.parentBits[2] + 8 * props.parentBits[1] + 16 * props.parentBits[0];
    const childValue = rule[parentsValue];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '1em', marginBottom: '1em' }}>
                    <h3 style={{ marginRight: '1em', marginTop: 0, marginBottom: 0 }}>Rule:</h3>
                    {rule.map((r, index) => <h3
                        onClick={() => {
                            const newRule = [...rule];
                            newRule[index] = (newRule[index] + 1) % 2;
                            setRule(newRule);
                        }}
                        style={{ cursor: 'pointer', marginTop: 0, marginBottom: 0 }}>{r}</h3>)}
                    <h3 style={{ marginLeft: '1em', marginTop: 0, marginBottom: 0 }}>(0x{getHexStringFromBitArray(rule)})</h3>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderWidth: 1, borderColor: 'black', width: 256 }}>
                {props.parentBits.map((bit, index) => <div
                    onClick={() => {
                        const newParentBits = [...props.parentBits];
                        newParentBits[index] = (newParentBits[index] + 1) % 2;
                        props.setParentBits(newParentBits);
                    }}
                    style={{ width: 50, height: 50, borderStyle: 'solid', borderWidth: 1, borderColor: 'black', backgroundColor: bit ? 'black' : 'white', cursor: 'pointer' }} />)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: 258 }}>
                {props.parentBits.map((bit, index) => <div
                    style={{
                        width: 50,
                        height: 50,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderBottomWidth: 2,
                        borderColor: index === 2 ? 'black' : '#00000000',
                        borderLeftColor: index === 2 || index === 3 ? 'black' : '#00000000',
                        borderRightColor: index === 1 || index === 2 ? 'black' : '#00000000',
                        backgroundColor: index === 2 ? (childValue ? 'black' : 'white') : undefined
                    }} />)}
            </div>
        </div>
    );
}
