import React, { useEffect } from 'react';
import CSS from 'csstype';
import { useLocation } from 'react-router-dom';
import { FeeMarketEIP1559Transaction } from '@ethereumjs/tx';
import Button from './Button';
import { generateMintTransaction, getNumMinted, mintWithMetaMask, sendTransaction, signTransaction } from '../nft';

function getCostOfNthToken(n: number): number {
    return (.0001) * (n * n);
}

export default function Mint() {
    const [numMinted, setNumMinted] = React.useState<number>(0);

    const [address, setAddress] = React.useState<string>('');
    const [mintingFee, setMintingFee] = React.useState<string>('');
    const [gasCost, setGasCost] = React.useState<string>('100');
    const [priorityFee, setPriorityFee] = React.useState<string>('3');
    const [transaction, setTransaction] = React.useState<FeeMarketEIP1559Transaction | undefined>(undefined);

    const [privateKey, setPrivateKey] = React.useState<string>('');
    const [signedTransaction, setSignedTransaction] = React.useState<string | undefined>(undefined);

    const [transactionResult, setTransactionResult] = React.useState<string | undefined>(undefined);

    const location = useLocation();
    const pathNamePieces = location.pathname.split('/');
    const tokenId = pathNamePieces.length >= 3 ? location.pathname.split('/')[2] : '0';

    const hasMetaMask = typeof (window as any).ethereum !== 'undefined';

    useEffect(() => {
        const setCurrentCost = async () => {
            const numMinted = await getNumMinted();
            const numMintedNumber = Number.parseInt(numMinted.toString());
            setNumMinted(numMintedNumber);
            setMintingFee((getCostOfNthToken(numMintedNumber + 1) + .00000001).toFixed(8));
        };
        setCurrentCost();
    }, []);

    const renderMetaMaskInfo = () => {
        return (
            <div style={{ marginTop: '3em', marginBottom: '3em' }}>
                <div style={{ marginBottom: '3em' }}>
                    <input style={{ width: '18em', fontSize: '1em' }} value={mintingFee} onChange={evt => setMintingFee(evt.target.value)} placeholder=".001" />
                    <p style={{ fontSize: '.5em', marginBottom: '3em' }}>Minting Fee (ETH)</p>
                    <Button text="Mint with MetaMask" onClick={async () => {
                        const txHash = await mintWithMetaMask(tokenId, mintingFee);
                        setTransactionResult(txHash);
                    }} />
                </div>
                {transactionResult ? <p style={{ marginBottom: '3em', wordBreak: 'break-all' }}>Transaction Hash: <a href={`https://etherscan.io/tx/${transactionResult}`} target="_blank" rel="noopener noreferrer">{transactionResult}</a></p> : undefined}
                <p>or proceed manually</p>
                <hr />
            </div>
        );
    };

    return (
        <div style={containerStyle}>
            <p>Minting an Automata NFT consists of calling the mint method on the Automata NFT smart contract (address <span style={{ wordBreak: 'break-all' }}>0x6572af38ffa542dfece2a34e8b74b42fc93d53f6</span>), passing the current minting fee as the value. This form will walk you through the process.</p>
            <p style={{ wordBreak: 'break-all' }}>Token ID: {tokenId}</p>
            <p>Note: check that the token is available <a href={`/token/${tokenId}`} target="_blank" rel="noopener noreferrer">here</a> before minting.</p>
            <p>Tokens minted: {numMinted}</p>
            <p>Cost to mint token #{numMinted + 1}: {getCostOfNthToken(numMinted + 1).toFixed(8)}ETH</p>
            <p>Cost to mint token #{numMinted + 2}: {getCostOfNthToken(numMinted + 2).toFixed(8)}ETH</p>
            <p>Cost to mint token #{numMinted + 3}: {getCostOfNthToken(numMinted + 3).toFixed(8)}ETH</p>
            <p>Note: you may want to send this last value to protect against the case that other tokens are minted ahead of yours.</p>
            <p>Note: the gas limit is set to 100000. Typically minting requires 70000 gas. The difference will be returned to you.</p>
            <p>Note: to reduce cost, you may want to wait until the gas price is low before minting.</p>
            <p>Note: the total cost of minting is the minting fee + the cost of gas (~70000*gas cost).</p>
            {hasMetaMask ? renderMetaMaskInfo() : undefined}
            <p style={{ marginTop: '2em' }}>1. Generate the transaction by providing your account address, minting fee, max gas price and priority fee (check <a href="https://etherscan.io/gastracker" target="_blank" rel="noopener noreferrer">here</a> for current reasonable values).</p>
            <div style={{ marginTop: '3em', marginBottom: '3em' }}>
                <input style={{ width: '18em', fontSize: '1em' }} value={address} onChange={evt => setAddress(evt.target.value)} placeholder="f39fd6e51aad88f6f4ce6ab8827279cfffb92266" />
                <p style={{ fontSize: '.5em', marginBottom: '3em' }}>Account Address</p>
                <input style={{ width: '18em', fontSize: '1em' }} value={mintingFee} onChange={evt => setMintingFee(evt.target.value)} placeholder=".001" />
                <p style={{ fontSize: '.5em', marginBottom: '3em' }}>Minting Fee (ETH)</p>
                <input style={{ width: '18em', fontSize: '1em' }} value={gasCost} onChange={evt => setGasCost(evt.target.value)} placeholder="100" />
                <p style={{ fontSize: '.5em', marginBottom: '3em' }}>Max Gas Cost (GWEI)</p>
                <input style={{ width: '18em', fontSize: '1em' }} value={priorityFee} onChange={evt => setPriorityFee(evt.target.value)} placeholder="3" />
                <p style={{ fontSize: '.5em', marginBottom: '3em' }}>Priority Fee (GWEI)</p>
                <Button text="Generate transaction" onClick={async () => {
                    const tx = await generateMintTransaction(`0x${tokenId}`, address, mintingFee, gasCost, priorityFee);
                    setTransaction(tx);
                }} />
            </div>
            {transaction ? <p>Transaction: {JSON.stringify(transaction.toJSON(), null, 2)}</p> : undefined}
            <p style={{ marginTop: '2em' }}>2. Sign the transaction by providing your private key (or sign and send the transaction yourself).</p>
            <div style={{ marginTop: '3em', marginBottom: '3em' }}>
                <input style={{ width: '18em', fontSize: '1em' }} value={privateKey} onChange={evt => setPrivateKey(evt.target.value)} placeholder="ac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80" />
                <p style={{ fontSize: '.5em', marginBottom: '3em' }}>Private Key</p>
                <Button text="Sign transaction" onClick={async () => {
                    const signedTx = await signTransaction(transaction!, privateKey);
                    setSignedTransaction(signedTx);
                }} />
            </div>
            {signedTransaction ? <p>Signed transaction: {signedTransaction}</p> : undefined}
            <p style={{ marginTop: '2em' }}>3. Send the transaction.</p>
            <div style={{ marginTop: '3em', marginBottom: '3em' }}>
                <Button text="Send transaction" onClick={async () => {
                    try {
                        const result = await sendTransaction(signedTransaction!);
                        setTransactionResult(result);
                    } catch (e: any) {
                        setTransactionResult(e.message);
                    }
                }} />
            </div>
            {transactionResult ? <p>Transaction Hash: <a href={`https://etherscan.io/tx/${transactionResult}`} target="_blank" rel="noopener noreferrer">{transactionResult}</a></p> : undefined}
        </div>
    );
}

const containerStyle: CSS.Properties = {
    paddingLeft: '2em',
    paddingRight: '2em',
    marginTop: '2em',
    maxWidth: '800px'
};
