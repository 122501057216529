import React from 'react';

interface P {
    bits: number[];
    setBits: (bits: number[]) => void;
}

export default function ParentStateExample(props: P) {
    const value = props.bits[4] + 2 * props.bits[3] + 4 * props.bits[2] + 8 * props.bits[1] + 16 * props.bits[0];

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderWidth: 1, borderColor: 'black', width: 250 }}>
                {props.bits.map((bit, index) => <div
                    onClick={() => {
                        const newBits = [...props.bits];
                        newBits[index] = (newBits[index] + 1) % 2;
                        props.setBits(newBits);
                    }}
                    style={{ width: 50, height: 50, borderStyle: 'solid', borderWidth: 1, borderColor: 'black', backgroundColor: bit ? 'black' : 'white', cursor: 'pointer' }} />)}
            </div>
            <h1 style={{ marginLeft: '1em', marginRight: '1em' }}>=</h1>
            <h1 style={{ width: '1.5em' }}>{value}</h1>
        </div>
    );
}
