import React from 'react';
import { getHexStringFromBitArray } from '../utils';
import { color } from './styles';

function generateNextRow(rule: number[], previousRow: number[]) {
    const nextRow: number[] = [];
    for (let i = 0; i < 224; i++) {
        const farLeft = i <= 1 ? 0 : previousRow[i - 2];
        const left = i === 0 ? 0 : previousRow[i - 1];
        const center = previousRow[i];
        const right = i === 223 ? 0 : previousRow[i + 1];
        const farRight = i >= 222 ? 0 : previousRow[i + 2];
        const parentIdx = farLeft * 16 + left * 8 + center * 4 + right * 2 + farRight;
        nextRow.push(rule[parentIdx]);
    }
    return nextRow;
}

function renderRow(row: number[], rowNum: number) {
    let pixelSize = '3px';
    if (window.innerWidth < 400) {
        pixelSize = '1px';
    } else if (window.innerWidth < 800) {
        pixelSize = '2px';
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }} key={rowNum}>
            {row.map((value, index) => {
                return (
                    <div style={{
                        width: pixelSize,
                        height: pixelSize,
                        backgroundColor: value ? 'black' : 'white'
                    }}
                    key={index} />
                );
            })}
        </div>
    );
}

interface P {
    rule: number[];
    initialRow: number[];
    owner?: string;
    unowned?: boolean;
}

export default function Automata(props: P) {
    const rows = [];
    let currentRow = props.initialRow;
    for (let i = 0; i < 224; i++) {
        rows.push(renderRow(currentRow, i));
        currentRow = generateNextRow(props.rule, currentRow);
    }

    const hexValue = getHexStringFromBitArray([...props.rule, ...props.initialRow]);

    const pMargin = props.owner || props.unowned ? '.1em' : undefined;
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {rows}
            </div>
            <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                <p style={{ display: 'inline', marginBottom: pMargin }}>0x</p>
                <p style={{ display: 'inline', color: color.ruleText, marginBottom: pMargin }}>{hexValue.substring(0, 8)}</p>
                <p style={{ display: 'inline', marginBottom: pMargin, wordBreak: 'break-all' }}>{hexValue.substring(8)}</p>
            </div>
            {props.owner ? <p style={{ marginTop: pMargin }}>Owned by {props.owner}</p> : undefined}
            {props.unowned ? <p style={{ marginTop: pMargin }}>Unowned</p> : undefined}
        </div>
    );
}
