export function getHexStringFromBitArray(bitArray: number[]) {
    let binaryValue = '';
    for (let v of bitArray) {
        binaryValue += v;
    }

    let hexValue = '';
    for (let i = 0; i < bitArray.length; i += 4) {
        hexValue += parseInt(binaryValue.substr(i, 4), 2).toString(16).toUpperCase();
    }

    return hexValue;
}
