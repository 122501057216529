import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './TopBar';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Mint from './Mint';

export default function Main() {
    return (
        <Router>
            <TopBar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/token/:id" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/mint/:id" element={<Mint />} />
                </Routes>
            </div>
        </Router>
    );
}
