import React from 'react';

interface P {
    text: string;
    onClick: () => void;
}

export default function Button(props: P) {
    return (
        <div className="clickable" onClick={props.onClick} style={{ width: '18em', display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 2, borderStyle: 'solid', borderColor: 'black', borderWidth: 1 }}>
            <p style={{ margin: 0 }}>{props.text.toUpperCase()}</p>
        </div>
    );
}
