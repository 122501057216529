import React from 'react';
import { NavLink } from 'react-router-dom';
import CSS from 'csstype';
import { color } from './styles';

export default function TopBar() {
    return (
        <div style={containerStyle}>
            <h1 style={headerStyle}>Automata NFT</h1>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1em' }}>
                <NavLink style={linkStyle} to="/">Home</NavLink>
                <p style={linkSeparatorStyle}>|</p>
                <NavLink style={linkStyle} to="/about">About</NavLink>
                <p style={linkSeparatorStyle}>|</p>
                <NavLink style={linkStyle} to="/contact">Contact</NavLink>
            </div>
        </div>
    );
}

const containerStyle: CSS.Properties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderColor: color.lightText,
    alignItems: 'flex-end',
    borderStyle: 'solid',
    borderWidth: '0px',
    borderBottomWidth: '1px',
    marginLeft: '1.5em',
    marginRight: '1.5em',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    paddingBottom: '.5em'
};

const headerStyle: CSS.Properties = {
    margin: 0,
    fontWeight: 100
};

const linkStyle: CSS.Properties = {
    color: color.lightText,
    textDecoration: 'none'
}

const linkSeparatorStyle: CSS.Properties = {
    margin: '1em',
    lineHeight: 0
}
