import CSS from 'csstype';

export const color = {
    background: '#f5f5f5',
    lightText: '#666666',
    mainText: '#333333',
    headerText: '#424242',
    ruleText: '#428442',
}

export const textStyle: CSS.Properties = {
    color: color.mainText,
    fontWeight: 100
};

export const textHeaderStyle: CSS.Properties = {
    color: color.headerText,
    fontWeight: 300
};
